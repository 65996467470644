<template>
  <div class="container pb-4">
    <div class="row">
      <div class="col-md-12">
        <base-card>
          <template #header>بيان الخصوصية</template>
          <template #body>
            <div class="row">
              <div class="col-sm-12 text-left">
                <h5>بيان الخصوصية</h5>
                <p>تود الاردنية الاولى للمزادات تقديم هذه الوثيقة لكل عملائها حرصا منها على توضيح سياسة التعامل الخاصة بها مع بيانات العملاء الشخصية وما ينتج عن التعامل بين العملاء و بين المواقع الالكترونبة الخاصة بنا.</p>
                <h5>جمع المعلومات الخاصة</h5>
                <p>نظرا لقيام الاردنية الاولى للمزادات بتقديم خدمة بيع السيارات من خلال المزادات فإن العديد من القوانين تلزمنا بالتدقيق فى المعلومات و البيانات الشخصية الخاصة بك, لذلك فإن الاردنية الاولى للمزادات عند القيام بالتسجيل في الموقع تطلب الاردنية الاولى للمزادات تزويدنا بالمعلومات التي تشمل الاسم بالكامل, الجنسية, تاريخ الميلاد, العنوان بالكامل, رقم هاتفك, البريدالالكتروني, بيانات بطاقة الهوية. هذه البيانات كما سبق الذكر تسهم في التزامنابالقوانين و تساعدنا على التواصل معك و تقديم خدمات الموقع بشكل صحيح</p>
                <h5>المعلومات التي يتم جمعها نتيجة زيارتك للموقع</h5>
                <p>عندما تقوم بزيارة هذا الموقع فإن الشركة التي تقدم لنا خدمةالانترنت(Internet Service Provider) تقوم بحفظ سجل لزيارتك. والمعلومات التالية يتم حفظها لأغراض إحصائية:</p>
                <ul>
                  <li>عنوان حاسوبك</li>
                  <li>تاريخ ووقت زيارتك للموقع</li>
                  <li>الصفحات التي قمت بزيارتها والوثائق التي قمت بتحميلها</li>
                  <li> المواقع السابقة التي قمت بزيارتها</li>
                </ul>
                <p>إننا لا نستخدم ولا نقوم بالكشف عن المعلومات الخاصة بك والتي تم جمعها لأي غرض من الاغراض انما تستخدم هذه البيانات لاغراض احصائية فقط للتحسين من اداء و تقديم خدمات الموقع.</p>
                <h5>البريد لإلكتروني</h5>
                <p>تتعهد الاردنية الاولى للمزادات بعدم الكشف عن بريدك الالكتروني الخاص بك لاى غرض من الاغراض و انما يتم تسجيل البريد الالكتروني الخاص بك فقط ليتم الاتصال بك من خلاله و ايفادك بمستجدات الموقع و اخر التطورات التي تهمك. كما تتعهدالاردنية الاولى للمزادات بعدم ادراج البريد الالكتروني الخاص بك في اى من قوائم المرسلات خارج نطاق مواقعنا.</p>
                <h5>رقم الهاتف النقال</h5>
                <p>تتعهد الاردنية الاولى للمزادات بعدم الكشف عن رقم الهاتف الخاص بك لاى غرض من الاغراض و انما يتم تسجيل رقم الهاتف النقال الخاص بك فقط ليتم الاتصال بك من خلاله و ايفادك بمستجدات الموقع و اخر التطورات التي تهمك. كما تتعهد الاردنية الاولى للمزادات بعدم ادراج الهاتف النقال الخاص بك في اى من قوائم المرسلات خارج نطاق مواقعنا.</p>
                <h5>الاستخدام والكشف عن المعلومات الشخصية</h5>
                <p>إننا لانستخدم ولا نقوم بالكشف عن المعلومات الشخصية التي نقوم بجمعها لأي غرض كان، عدا عن الغرض الذي جُمعت من أجله وبدون الحصول أولا على موافقتك، أوفي الحالات التي تطلب فيها هذه المعلومات وفقا للقانون. وفيما يتعلق بغرضنا الأساسي، فإن المعلومات التي يتم تسجيلها هي بغرض تقديم خدمة البيع من خلال المزادات و تحديد هوية المشترين كما يتم تقديم تقرير بالفائزين بالمزاد بعد نهاية كل مزاد.</p>
                <h5>نوعيّةالمعلومات</h5>
                <p>إن هدفنا التأكد من أن معلوماتك الشخصية صحيحة ودقيقة وكاملة وحديثة. ولمساعدتنا في هذا الأمر، يرجى منك الاتصال بنا إذا طرأ تغيير على أي من المعلومات التي قدمتها لنا. وإذا اعتقدت أن المعلومات المتعلقة بك والتي بحوزتنا غير دقيقة أو غير وافية أو حديثة، فاتصل بنا وسنقوم باتخاذ كل التدابيرالضرورية لتصحيح المعلومات.</p>
                <h5>سلامة المعلومات الشخصية</h5>
                <p>الاردنية الاولى للمزادات ملتزمة بالمحافظة على سلامة المعلومات التي تقدمها لنا وسنقوم باتخاذ كافة التدابير الملائمة للمحافظة على معلوماتك الشخصية من الضياع أو سوء الاستخدام أو التحريف والتغيير.</p>
                <h5>كيفية الحصول على معلوماتك الشخصية</h5>
                <p>يمكنك أن تطلب منا الحصول على معلوماتك الشخصية التي بحوزتنا. وسنقوم بتقديمها لَك إن أمكن ذلك. وإذا ما قمنا برفض طلبك فإننا سنقوم بشرح أسباب الرفض. إن المعلومات التي يمكن أن نقدمها لك محصورة بسجلاتنا المتعلقة بعنوانك ورقم هاتفك وتفاصيل بطاقة هويتك وحساباتك معنا وكشوف تلك الحسابات.</p>
                <h5>الكوكيز(Cookies)</h5>
                <p>يقوم هذا الموقعب استخدام كل من ملفات الارتباط المؤقتة(session) وملفات الارتباط الدائمة(persistent cookies) كي نتمكن من تزويدك بصفحة آمنة للأعضاء وللتأكد أيضاً من قيامك بقراءة اتفاقية الزوار. كذلك فإن الكوكيز تمكننا من القيام بتحليل إحصائي كي نقوم بتطوير وتحسين هذا الموقع. إننا لا نقوم بتحليل تصرفات الأفراد. كذلك فإن جميع المعلومات التي نجمعها تخضع لسياسية الخصوصية الخاصة بنا. وإذا لم ترغب باستقبال كوكيز يمكنك إعداد المتصفّح ليقوم برفضها. على أية حال فإن هذا يعني انه لن يكون بإمكانك الاستفادة بشكل كامل من المزايا التي يوفرها الموقع. فعلى سبيل المثال لن يكون بإمكانك شراء سيارة اونلاين بدون الدخول إلى كل صفحة. المواد الدعائية.</p>
                <h6>
                  <span>إذا قمت بالتسجيل للحصول على معلومات منا وغيرت رأيك فيما بعد، يمكنك الاتصال بنا وطلب حذف اسمك من قوائم التوزيع. هناك عدة خيارات متوفرة</span>
                  &nbsp;<router-link :to="{name: 'contact'}">الاتصال بنا</router-link>
                </h6>
                <p>كذلك يمكنك الاطلاع على ومعاينة وثيقتنا الخاصة بسياسة الخصوصية في مكتبنا. وتبين هذه الوثيقة سياساتنا الخاصة بمسألة إدارة معلوماتك الشخصية.</p>
                <h5>الشكاوى الخاصة بالخصوصية</h5>
                <p>
                  <span>إذا رغبت بتقديم شكوى تتعلق بخرقٍ أو احتمال حدوث خرق لسياسة الخصوصية أو أسس الخصوصية الوطنية(National Privacy Principles)، يمكنك الاتصال بنا. هناك عدة خيارات متوفرة من خلال</span>
                  &nbsp;<router-link :to="{name: 'contact'}">الاتصال بنا</router-link>
                </p>
              </div>
            </div>
          </template>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
export default {
  components: {BaseCard}
}
</script>

<style scoped>
* {
  text-align: justify;
}

h5, h6 {
  color: #f44336;
}
</style>
